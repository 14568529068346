import React, { Component } from 'react';
import Helmet from 'react-helmet';

import App from '../../../layout/App';
import Panel from '../../../core/Panel';

import './index.css';

export class PrivacyPolicyPage extends Component {
  static propTypes = {};

  render() {
    return (
      <App>
        <div className="component privacy-policy-page">
          <Helmet title="Privacy Policy – ThinkReservations">
            <meta
              name="description"
              content="At ThinkReservations we are dedicated to your protecting your privacy and safeguarding your reservation information."
            />
          </Helmet>

          <Panel>
            <h1>Privacy Policy</h1>
            <p>Last modified: July 21, 2022</p>

            <h2>Introduction</h2>
            <p>
              DeepThink LLC dba ThinkReservations (“Company”, “we,” or “us”)
              respect your privacy and are committed to protecting it through
              our compliance with this policy.
            </p>
            <p>
              This policy describes the types of information we may collect from
              you or that you may provide when you visit the website
              thinkreservations.com (our “Website”) or use our related
              applications and tools (collectively, with the Website, the
              “Services”) and our practices for collecting, using, maintaining,
              protecting, and disclosing that information.
            </p>
            <p>
              This policy applies to information we collect on our Website and
              Services.
            </p>
            <p>
              Please read this policy carefully to understand our policies and
              practices regarding your information and how we will treat it. By
              accessing or using our Services, you agree to this privacy policy.
              This policy may change from time to time (see Changes to Our
              Privacy Policy). Your continued use of our Services after we make
              changes is deemed to be acceptance of those changes, so please
              check the policy periodically for updates.
            </p>
            <h2>Children Under the Age of 18</h2>
            <p>
              Our Services are not intended for children under 18 years of age.
              No one under age 18 may provide any personal information to or on
              the Services. We do not knowingly collect personal information
              from children under 18. If you are under 18, do not use or provide
              any information through the Services or provide any information
              about yourself to us, including your name, address, telephone
              number, email address, or any screen name or user name you may
              use. If we learn we have collected or received personal
              information from a child under 18 without verification of parental
              consent, we will delete that information. If you believe we might
              have any information from or about a child under 18, please
              contact us at info@thinkreservations.com.
            </p>
            <h2>Information We Collect About You and How We Collect It</h2>
            <h3>Personal Information</h3>
            <p>
              We collect several types of information from and about users of
              our Services, including information by which you may be personally
              identified, such as name, postal address, e-mail address,
              telephone number, and any other identifier by which you may be
              contacted online or offline (“personal information”);
            </p>
            <p>
              We collect this information when you directly provide it to us
              such as during the signup process or user creation.
            </p>
            <h3>Information You Provide to Us</h3>
            <p>
              The information we collect on or through our Services may include:
            </p>
            <ol>
              <li>
                Information that you provide while using our Services. This
                includes information provided at the time of registering to use
                our Services, subscribing to our service, posting material,
                searching for, making or modifying reservations, reporting
                problems, or requesting further services.
              </li>
              <li>
                Records and copies of your correspondence (including email
                addresses), if you contact us.
              </li>
              <li>
                Your responses to surveys that we might ask you to complete for
                research purposes.
              </li>
              <li>
                Details of transactions you carry out through our Services and
                of the fulfillment of your orders, including records of
                reservations. You may be required to provide billing information
                before starting use of our Services.
              </li>
            </ol>

            <h3>
              Information We Collect Through Automatic Data Collection
              Technologies
            </h3>
            <p>
              As you navigate through and interact with our Services, we may use
              automatic data collection technologies to collect certain
              information about your equipment, browsing actions, and patterns,
              including:
            </p>
            <ol>
              <li>
                Details of your visits to our Website, including traffic data,
                location data, logs, and other communication data and the
                resources that you access and use on the Website.
              </li>
              <li>
                Information about your computer and internet connection,
                including your IP address, operating system, and browser type.
              </li>
            </ol>
            <p>
              The information we collect automatically may include personal
              information.
            </p>
            <p>
              The technologies we use for this automatic data collection may
              include:
            </p>
            <ol>
              <li>
                <strong>Cookies (or browser cookies).</strong> A cookie is a
                small file placed on the hard drive of your computer. You may
                refuse to accept browser cookies by activating the appropriate
                setting on your browser. However, if you select this setting you
                may be unable to access certain parts of our Website. Unless you
                have adjusted your browser setting so that it will refuse
                cookies, our system will issue cookies when you direct your
                browser to our Website.
              </li>
              <li>
                <strong>Web Beacons.</strong> Pages of our the Website and our
                e-mails may contain small electronic files known as web beacons
                (also referred to as clear gifs, pixel tags, and single-pixel
                gifs) that permit the Company, for example, to count users who
                have visited those pages or opened an email and for other
                related website statistics (for example, recording the
                popularity of certain website content and verifying system and
                server integrity).
              </li>
            </ol>

            <h2>Third-Party Use of Cookies and Other Tracking Technologies</h2>
            <p>
              Some content or applications, used by the Website or Services are
              served by third-parties. These third parties may use cookies alone
              or in conjunction with other tracking technologies to collect
              information about you when you use our Website or Services. The
              information they collect may be associated with your personal
              information or they may collect information, including personal
              information, about your online activities over time and across
              different websites and other online services. They may use this
              information to provide targeted content.
            </p>
            <p>
              We do not control these third parties’ tracking technologies or
              how they may be used. If you have any questions about targeted
              content, you should contact the responsible provider directly.
            </p>
            <h2>How We Use Your Information</h2>
            <p>
              We use information that we collect about you or that you provide
              to us, including any personal information:
            </p>
            <ol>
              <li>To present our Website and its contents to you.</li>
              <li>
                To provide you with information, products, or Services that you
                request from us.
              </li>
              <li>To fulfill any other purpose for which you provide it.</li>
              <li>
                To provide you with notices about your account/subscription.
              </li>
              <li>
                To carry out our obligations and enforce our rights arising from
                any contracts entered into between you and us, including for
                billing and collection.
              </li>
              <li>
                To notify you about changes to our Services or any products or
                services we offer or provide though it.
              </li>
              <li>
                To allow you to participate in interactive features on our
                Services.
              </li>
              <li>
                In any other way we may describe when you provide the
                information.
              </li>
              <li>For any other purpose with your consent.</li>
            </ol>

            <p>
              We may also use your information to contact you about our own and
              third-parties’ goods and services that may be of interest to you.
              If you do not want us to use your information in this way, please
              unsubscribe using the instructions in the content you received.
              For information about how you can opt out, see{' '}
              <emphasis>
                Choices About How We Use and Disclose Your Information
              </emphasis>
              .
            </p>

            <h2>Disclosure of Your Information</h2>
            <p>
              No mobile information will be shared with third parties/affiliates
              for marketing/promotional purposes.
            </p>
            <p>
              We may disclose aggregated information about our users, and
              information that does not identify any individual, without
              restriction.
            </p>
            <p>
              We may disclose personal information that we collect or you
              provide as described in this privacy policy:
            </p>

            <ol>
              <li>To our subsidiaries and affiliates.</li>
              <li>
                To contractors, service providers, and other third parties we
                use to support our business and who are bound by contractual
                obligations to keep personal information confidential and use it
                only for the purposes for which we disclose it to them.
              </li>
              <li>
                To a buyer or other successor in the event of a merger,
                divestiture, restructuring, reorganization, dissolution, or
                other sale or transfer of some or all of DeepThink LLC dba
                ThinkReservations’ assets, whether as a going concern or as part
                of bankruptcy, liquidation, or similar proceeding, in which
                personal information held by DeepThink LLC dba ThinkReservations
                about our Services users is among the assets transferred.
              </li>
              <li>
                To third parties to market their products or services to you if
                you have consented to these disclosures.
              </li>
              <li>To fulfill the purpose for which you provide it. </li>
              <li>
                For any other purpose disclosed by us when you provide the
                information.
              </li>
              <li>With your consent.</li>
            </ol>

            <p>We may also disclose your personal information:</p>
            <ol>
              <li>
                To comply with any court order, law, or legal process, including
                to respond to any government or regulatory request.
              </li>
              <li>
                To enforce or apply our terms of use
                https://thinkreservations.com/terms and other agreements,
                including for billing and collection purposes.
              </li>
              <li>
                If we believe disclosure is necessary or appropriate to protect
                the rights, property, or safety of the Company, our customers,
                or others.
              </li>
            </ol>

            <h2>Choices About How We Use and Disclose Your Information</h2>
            <p>
              We strive to provide you with choices regarding the personal
              information you provide to us. We have created mechanisms to
              provide you with the following control over your information:
            </p>
            <ol>
              <li>
                <strong>Tracking Technologies and Advertising.</strong> You can
                set your browser to refuse all or some browser cookies, or to
                alert you when cookies are being sent. If you disable or refuse
                cookies, please note that some parts of this site may then be
                inaccessible or not function properly.
              </li>
              <li>
                <strong>Promotional Offers from the Company.</strong> If you do
                not wish to have your email address used by the Company to
                promote our own or third parties’ products or services, you can
                opt-out by unsubscribing to the emails that you receive.
              </li>
            </ol>

            <h2>Accessing and Correcting Your Information</h2>
            <p>
              You can review and change your personal information by logging
              into the Website and visiting your account profile page.
            </p>
            <h2>Changes to Our Privacy Policy</h2>
            <p>
              It is our policy to post any changes we make to our privacy policy
              on this page. If we make material changes to how we treat our
              users’ personal information, we will notify you by email. The date
              the privacy policy was last revised is identified at the top of
              the page. You are responsible for ensuring we have an up-to-date
              active and deliverable email address for you, and for periodically
              visiting our Website and this privacy policy to check for any
              changes.
            </p>
            <h2>Contact Information</h2>
            <p>
              To ask questions or comment about this privacy policy and our
              privacy practices, contact us at:
              <br />
              <a href="mailto:info@thinkreservations.com">
                info@thinkreservations.com
              </a>
            </p>
          </Panel>
        </div>
      </App>
    );
  }
}

export default PrivacyPolicyPage;
